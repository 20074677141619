<div class="container-fluid h-100 w-100">
    <div class="row flex-nowrap h-100">
        <div class="col-auto p-0">
            <app-side-nav-bar></app-side-nav-bar>
        </div>
        <div class="col p-0">
            <div class="container-fluid page-backdrop align-items-top w-100 h-100 m-0 p-0 no-gutters">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>